require('./bootstrap');
window.Popper = require("popper.js").default;
window.$ = window.jQuery = require("jquery");
require('lightbox2');

const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return "mobile";
    }
    return "desktop";
};

let dialup_button_href = document.getElementById("dialup_button_container");
$(window).ready(function () {
    if (getDeviceType() === "mobile") {
        dialup_button_href.setAttribute("href", "tel:0281972810");
    } else {
        dialup_button_href.setAttribute("href", "/kontakt");
    }
})
